import $ from 'jquery';
import Swiper from 'swiper';

export default class Home {
    constructor() {
        this.sliderHeader;
        this.categories = $('.home section .categories');

        if ($('.slider-header').length > 0) {
            this.slider();
        }

        $(document).on('click', '.home main header .controls .prev', (e) => {
            this.sliderHeader.swipePrev();
        });

        $(document).on('click', '.home main header .controls .next', (e) => {
            this.sliderHeader.swipeNext();
        });

        $('main section .tabulation ul li').first().addClass('actif');
        $('.home section .categories .categorie-conteneur').first().addClass('actif');

        this.doctors();

        $(document).on('click', 'main section .tabulation ul li', (e) => {
            this.manageDoctors(e);
        });
    }

    slider() {
        let obj = $('header .row .titles .actif');

        this.sliderHeader = new Swiper('.slider-header', {
            mode: 'horizontal',
            loop: true,
            calculateHeight: true,
            autoplay: 5000,
            speed: 750,
            simulateTouch: false,
            onSwiperCreated: (sliderHeader) => {
                let nbSlides = $('.home header .row.slider .images img').length;
                $('.home header .row > .pagination .max').html('0' + nbSlides);
            },
            onSlideChangeStart: (sliderHeader) => {
                $('header .row .titles .actif').css('animation', 'skew2 1.55s cubic-bezier(0.7, 0.36, 0.1, 1)');

                $('header .row .titles .actif, header .slider .images .actif').removeClass('actif');

                $('header .row .titles').children().eq(sliderHeader.activeLoopIndex).addClass('actif');
                $('header .slider .images').children().eq(sliderHeader.activeLoopIndex).addClass('actif');

                $('.pagination span.current').html('0' + (sliderHeader.activeLoopIndex + 1));
            },
            onSlideChangeEnd: () => {
                $('header .row .titles h1, header .row .titles p').css('animation', '');
            }
        });
    }

    doctors() {
        let getTabHeight = $('.home section .categories .categorie-conteneur.actif').height();
        this.categories.height(getTabHeight);
    }

    manageDoctors(elt) {
        $('.home section .categories .categorie-conteneur, main section .tabulation ul li').removeClass('actif');
        $(elt.currentTarget).addClass('actif');
        $('.home section .categories .categorie-conteneur[data-type="' + elt.currentTarget.dataset.type + '"]').addClass('actif');
        this.doctors();
    }
}