import $ from 'jquery';
import Swiper from 'swiper';

export default class Page {
    constructor() {
        $('.slider-rte').each((index, element) => {
            this.slider(element);
        });

        $('[data-youtube] .play').click((e) => {
            this.video($(e.currentTarget).parent());
        });

        $('.video-container .play').mousemove(function (e) {
            let x = e.pageX - $(this).offset().left - $(this).width() / 2;
            let y = e.pageY - $(this).offset().top - $(this).height() / 2;

            $('.parallax').each(function () {
                let factor = parseFloat($(this).data("mouse-parallax"));
                x = -x * factor;
                y = -y * factor;

                $(this).css({
                    transform: "translate3d( " + x + "px, " + y + "px, 0 )"
                });
            });
        });

        $('.video-container .play').mouseleave(function (e) {
            let target = $(e.target);

            if (!target.is("div.layer")) {
                $('.parallax').each(function () {
                    $(this).css({
                        transform: "translate3d( 0px, 0px, 0 )"
                    });
                });
                e.stopPropagation();
            }
        });

        $('.video-container .close').click( (e) => {
            let elt = $(e.currentTarget);
            elt.siblings
            elt.siblings('.play').fadeIn();
            elt.siblings('img').fadeIn();
            elt.parent().removeClass('play');
            setTimeout(() => {
                elt.siblings('iframe').remove();
            }, 200);
        });
    }

    slider(element) {
        let swiper = new Swiper(element, {
            mode: 'horizontal',
            loop: true,
            calculateHeight: true,
            speed: 750
        });

        let getControl = $(element).siblings('.controls');

        getControl.on('click', '.prev', (event) => {
            event.stopPropagation();
            swiper.swipePrev();
        });

        getControl.on('click', '.next', (event) => {
            event.stopPropagation();
            swiper.swipeNext();
        });
    }

    video(videoContainer) {
        let src = videoContainer.data('youtube');
        videoContainer.append('<iframe src="' + src + '" allowfullscreen frameborder="0"></iframe>');
        videoContainer.find('.play').fadeOut();
        videoContainer.find('img').fadeOut();
        videoContainer.addClass('play');
    }
}