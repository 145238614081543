export default class Contact {
    constructor() {
        this.parentSelect = document.querySelector('select#parent-subject');
        this.childSelect = document.querySelector('select#child-subject');
        // Save all options
        this.childOptions = Array.from(this.childSelect.options);

        this.parentSelect.addEventListener('change', ({target: {value}}) => this.filterSubjects(value));
        this.filterSubjects(this.parentSelect.value);
    }

    filterSubjects(value) {
        // Remove current options
        Array.from(this.childSelect.options).forEach(option => this.childSelect.removeChild(option));
        // Append filtered options
        let filteredOptions = this.childOptions.filter(option => option.dataset.parent === value);
        filteredOptions.forEach(option => this.childSelect.appendChild(option));
        if (filteredOptions.length) {
            this.childSelect.parentElement.style.display = '';
            this.childSelect.disabled = false;
        } else {
            this.childSelect.parentElement.style.display = 'none';
            this.childSelect.disabled = true;
        }
    }
}