import $ from 'jquery';
import Parallax from 'parallax-scroll';

import Responsive from './responsive';

export default class Scroll {
    constructor() {
        this.currentScroll = $(document).scrollTop();
        this.lastScrollTop = 0;
        this.navElm = $('nav.nav');

        if ($('.home').length > 0) {
            let offset = $('.home section .tabulation ul').offset();
            this.offsetTabs = {
                top: offset.top,
                bottom: offset.top + $('.home section .categories').outerHeight()
            };
        }

        if ($('.cv').length > 0) {
            this.offsetCv = $('main section .tabulation').offset().top - 70;
        }

        $(window).on('scroll', () => {
            this.currentScroll = $(document).scrollTop();
            this.nav();

            if ($('.cv').length > 0) {
                this.cv();
            }

            if ($('.home').length > 0 && !Responsive.isMobile()) {
                this.tabsHome();
                this.draw();
            }

            this.lastScrollTop = this.currentScroll;
        });

        const parallax = new Parallax('.push', {
            speed: 0.1
        });

        if (!Responsive.isMobile()) {
            parallax.animate();
        }

        if ($('body').hasClass('home')) {
            this.smooth();
        }
    }

    nav() {
        if (
            this.currentScroll > this.lastScrollTop
            && this.currentScroll > this.navElm.height()
            && !this.navElm.hasClass('hidden')
        ) {
            this.navElm.addClass('hidden');
            $('section .tabulation ul').removeClass('down');
        } else if (this.currentScroll < this.lastScrollTop && this.navElm.hasClass('hidden')) {
            this.navElm.removeClass('hidden');
        }

        if (this.currentScroll < this.lastScrollTop && !$('section .tabulation ul').hasClass('down')) {
            $('section .tabulation ul').addClass('down');
        }
    }

    cv() {
        if (this.currentScroll > this.offsetCv) {
            $('.cv .onglets .scroll').addClass('visible');
        } else {
            $('.cv .onglets .scroll').removeClass('visible');
        }
    }

    tabsHome() {
        if (this.offsetTabs.top < this.currentScroll && this.currentScroll < this.offsetTabs.bottom) {
            $('section .tabulation ul').addClass('fixed');
            $('nav .sub').first().addClass('small');
        } else {
            $('section .tabulation ul').removeClass('fixed');
            $('nav .sub').first().removeClass('small');
        }

        if (this.currentScroll > this.offsetTabs.bottom) {
            $('section .tabulation ul').removeClass('fixed down');
        }
    }

    draw() {
        const getPointsForts = $('.home section .points-forts');
        if (this.currentScroll > getPointsForts.offset().top) {
            $('.home section .points-forts > div.exergue svg.big').css('stroke-dashoffset', '0px')
        }

        const getSeparator = $('.home section .points-forts > div.separateur');
        if (this.currentScroll > (getSeparator.offset().top - $(window).width() / 3)) {
            $('.home section .points-forts > div.separateur span').css('width', 'calc(100% / 6 * 5)')
        }
    }

    smooth() {
        $('a[href*="#"]')
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(event => {
                let anchor = event.target;
                if (location.pathname === anchor.pathname && location.hostname === anchor.hostname) {
                    let target = $(anchor.hash);
                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 1500, () => {
                            let $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) {
                                return false;
                            }
                            $target.attr('tabindex', '-1');
                            $target.focus();
                        });
                    }
                }
            });
    }
}