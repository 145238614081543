import $ from 'jquery';

export default class Cv {
    constructor(){
        this.onglets = $('.cv .onglets');
        if($('.page-template-cv').length > 0){
            this.offset_cv = $('main section .tabulation').offset().top - 70;
        }

        $('.cv .tabulation ul li').first().addClass('actif');
        $('.cv .onglets .onglet').first().addClass('actif');

        $(document).on('click', '.cv .onglets .scroll', () => {
            $('html, body').animate({
                scrollTop: this.offset_cv
            }, 400);
        });

        this.tabs();

        $(document).on('click', '.cv .tabulation ul li', (e) => {
            this.manageTabs(e);
        });
    }
    tabs(){
        let getTabHeight = $('.cv .onglets .onglet.actif').outerHeight();
        this.onglets.height(getTabHeight);
    }
    manageTabs(elt){
        $('.cv .onglets .onglet, .cv .tabulation ul li').removeClass('actif');
        $(elt.currentTarget).addClass('actif');
        $('.cv .onglets .onglet[data-tab="' + elt.currentTarget.dataset.type + '"]').addClass('actif');
        this.tabs();
    }
}