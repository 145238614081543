import $ from 'jquery';
import objectFitImages from 'object-fit-images';
import SmoothScroll from 'smoothscroll-for-websites';

import Home from './home';
import Page from './page';
import Cv from './cv';
import Scroll from './scroll';
import Responsive from './responsive';
import Contact from './contact';

$(() => {
    objectFitImages();

    SmoothScroll({
        stepSize: 150,
        animationTime: 800,
        keyboardSupport: true,
        arrowScroll: 150
    });

    new Home();
    new Page();
    new Cv();
    new Scroll();

    if ($(document.body).hasClass('page-template-contact')) {
        new Contact();
    }

    $(document).on('click', 'nav .burger', (e) => {
        $(e.currentTarget).toggleClass('open');
        $('nav .row').first().toggleClass('open');
        $('nav .row > svg').toggleClass('visible');
    });

    $(document).on('click', 'nav .sub > div.btn', () => {
        $('nav .row.sub > div').first().toggleClass('visible');
    });

    $(document).on('click', 'nav .row .container ul li a', () => {
        if (Responsive.isMobile()) {
            $('nav .row').first().removeClass('open');
            $('nav .row > svg').removeClass('visible');
            $('nav .burger').removeClass('open');
        }
    });

    $(document).on('click', '.toggle-citron', () => {
        tarteaucitron.userInterface.openPanel();
    });
});
